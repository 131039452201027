let url = "";
let googleClientId = "159509563600-a6pcc2ri8foobtvffd75tvavvuc18lrd.apps.googleusercontent.com";

if (process.env.NODE_ENV === "development") {
    url = process.env.REACT_APP_URL;
} else {
    url = "https://api.faempreendimentos.eng.br";
}

export const Constants = {
    baseUrl: url + "/api/web",
    url,
    googleClientId,
};

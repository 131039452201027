import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../components/SplashScreen";

const InicioPage = lazy(() => import("./inicio/InicioPage"));
const PerfilEditPage = lazy(() => import("./perfil/PerfilEditPage"));
const NotificacaoPage = lazy(() => import("./notificacao/NotificacaoPage"));
const ObraPage = lazy(() => import("./obra/ObraPage"));
const ObraEtapaPage = lazy(() => import("./obra/ObraEtapaPage"));
const ArquivoPage = lazy(() => import("./arquivo/ArquivoPage"));
const DiarioPage = lazy(() => import("./diario/DiarioPage"));
const EtapaPage = lazy(() => import("./etapa/EtapaPage"));
const EtapaLancamentoPage = lazy(() => import("./etapa/EtapaLancamentoPage"));
const CotacaoPage = lazy(() => import("./cotacao/CotacaoPage"));
const CotacaoDetalhePage = lazy(() => import("./cotacao/CotacaoDetalhePage"));
const FinanceiroPage = lazy(() => import("./financeiro/FinanceiroPage"));
const SolicitacaoPage = lazy(() => import("./solicitacao/SolicitacaoPage"));
const SolicitacaoRespostaPage = lazy(() => import("./solicitacao/SolicitacaoRespostaPage"));
const SolicitacaoMensagemPage = lazy(() => import("./solicitacao/SolicitacaoMensagemPage"));
const SolicitacaoNovoPage = lazy(() => import("./solicitacao/SolicitacaoNovoPage"));

export default function HomePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact from="/" to="/inicio" />
                <Redirect from="/auth" to="/inicio" />

                <Route path="/inicio" component={InicioPage} />
                <Route path="/perfil" component={PerfilEditPage} />
                <Route path="/notificacao" component={NotificacaoPage} />
                <Route path="/obra/:id/cotacao/:id_cotacao" component={CotacaoDetalhePage} />
                <Route path="/obra/:id/financeiro" component={FinanceiroPage} />
                <Route path="/obra/:id/cotacao" component={CotacaoPage} />
                <Route path="/obra/:id/diario" component={DiarioPage} />
                <Route path="/obra/:id/arquivo" component={ArquivoPage} />
                <Route path="/obra/:id/solicitacao/:id_solicitacao/mensagem" component={SolicitacaoMensagemPage} />
                <Route path="/obra/:id/solicitacao/novo" component={SolicitacaoNovoPage} />
                <Route path="/obra/:id/solicitacao/:id_solicitacao" component={SolicitacaoRespostaPage} />
                <Route path="/obra/:id/solicitacao" component={SolicitacaoPage} />
                <Route path="/obra/:id/lancamento/:id_lancamento" component={EtapaLancamentoPage} />
                <Route path="/obra/:id/etapa/:id_etapa" component={EtapaPage} />
                <Route path="/obra/:id/etapa" component={ObraEtapaPage} />
                <Route path="/obra/:id" component={ObraPage} />

                <Redirect to="/" />
            </Switch>
        </Suspense>
    );
}

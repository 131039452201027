import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import store, { persistor } from "./app/store/store";
import axios from "axios";
import { setupAxios } from "./app/utils/AuthUtils";
import App from "./App";
import "./index.scss";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Constants } from "./app/utils/Constants";

const { PUBLIC_URL } = process.env;

// Interceptador do axios para verificar
// se possui token e configurar header.
setupAxios(axios, store);

ReactDOM.render(
    <GoogleOAuthProvider clientId={Constants.googleClientId}>
        <App store={store} persistor={persistor} basename={PUBLIC_URL} />
    </GoogleOAuthProvider>,
    document.getElementById("root")
);

serviceWorkerRegistration.register();

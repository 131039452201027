import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../components/SplashScreen";

const LoginPage = lazy(() => import("./LoginPage"));
const OnboardingPage = lazy(() => import("./OnboardingPage"));
const EsqueceuSenhaPage = lazy(() => import("./EsqueceuSenhaPage"));
const RecuperarSenhaPage = lazy(() => import("./RecuperarSenhaPage"));
const CodigoSenhaPage = lazy(() => import("./CodigoSenhaPage"));

export default function AuthPage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact from="/" to="/onboarding" />
                <Redirect from="/dashboard" to="/onboarding" />

                <Route path="/onboarding" component={OnboardingPage} />
                <Route path="/login" component={LoginPage} />
                <Route path="/esqueceu-senha" component={EsqueceuSenhaPage} />
                <Route path="/codigo-senha" component={CodigoSenhaPage} />
                <Route path="/recuperar-senha" component={RecuperarSenhaPage} />

                <Redirect to="/" />
            </Switch>
        </Suspense>
    );
}
